import React from 'react';
import { ButtonComponent } from '../../V2/Button/Button';
import { SkinnyCTA } from '../SkinnyCTA/SkinnyCTA';
import { Reviews } from '../Reviews/Reviews';
import { FeatureSlider } from '../FeatureSlider/FeatureSlider';

interface ComponentType {
  componentName: string;
  props?: object;
}

interface Props {
  Components?: ComponentType[];
}

export const ComponentMap = ({ Components = [], ...props }: Props) => {
  return (
    <>
      {Components.map((component, index) => {
        switch (component.componentName) {
          case 'SkinnyCTA':
            return <SkinnyCTA key={index} {...component} />;
          case 'Reviews':
            return <Reviews key={index} {...component} />;
          case 'FeatureSlider':
            return <FeatureSlider key={index} {...component} />;
          default:
            return <h1>Component Not Found: {component.componentName}</h1>;
        }
      })}
    </>
  );
};
