import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { IconGridComponentStyles, TableWrap, ItemStyle } from './styles.jsx';

interface Props {
  id?: string;
  Title?: string;
  Subtitle?: string;
  Items?: object;
  Theme?: 'light' | 'dark';
}

export const IconGrid = ({ id = '', Title = '', Subtitle = '', Items = [], Theme = 'light', ...props }: Props) => {
  return (
    <IconGridComponentStyles className={'theme-' + Theme} id={id}>
      <GlobalStyle />
      <div className={'container'}>
        <h4>{Subtitle}</h4>
        <h2>{Title}</h2>
        <TableWrap className={`counter-${Items.length}`}>
          {Items.map((item, index) => (
            <ItemStyle className={`theme-${Theme} `} key={index}>
              {item.Icon && <img alt={''} src={item.Icon} />}
              {item.image && <img alt={''} src={item.image} />}

              {item.Title && <h5>{item.Title}</h5>}
              {item.title && <h5>{item.title}</h5>}

              {item.Copy && <p>{item.Copy}</p>}
              {item.copy && <p>{item.copy}</p>}
            </ItemStyle>
          ))}
        </TableWrap>
      </div>
    </IconGridComponentStyles>
  );
};
