import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`
    padding: 32px 0;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 64px 0;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        padding: 120px 0;
    }

    .container {
        max-width: calc(100% - 32px);
        padding: 24px;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding: 48px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            padding: 64px;
        }

        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 91px 36px rgba(255, 255, 255, 0.01), 0px 51px 31px rgba(255, 255, 255, 0.04), 0px 23px 23px rgba(79, 79, 79, 0.06), 0px 6px 12px rgba(63, 63, 63, 0.07), 0px 0px 0px rgba(255, 255, 255, 0.07);
        border-radius: 16px;

        border: solid 4px white;

        backdrop-filter: blur(10px);

        .inner {

            h2 {
                text-wrap: balance;
                @media (min-width: ${mediaQueriesSizes.xxl}px) {
                    max-width: calc(100% - 250px);
                }
            }

            @media (min-width: ${mediaQueriesSizes.xxl}px) {
                display: flex;
                align-items: row;
                justify-content: space-between;
            }

            .btn-wrap {
                align-content: center;
            }

            .custom-button {
                margin-top: 16px;
                @media (min-width: ${mediaQueriesSizes.xxl}px) {
                    margin-top: 0;

                }
            }

        }
    }

    &.size-small {
        .container {
            h2 {
                font-size: 24px;
                font-family: centrano2-book;
                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    font-size: 32px;
                }

            }
        }
    }

`;
