import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`
    overflow: hidden;
    padding: 0;
    position: relative;
    background-image: url('/assets/2024/backgrounds/mesh.png');
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    .container {
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            display: flex;
        }

        h2 {
            min-width: 300px !important;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                padding-right: 16px;
            }
        }

    }

    hr.divider {
        margin: 32px 0;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100px;
            background: rgb(248,247,246);
            background: linear-gradient(0deg, rgba(248,247,246,1) 25%, rgba(248,247,246,0) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            
        }
    }

`;
export const ReviewContainer = styled.div`

    position: relative;
    padding: 32px 0 0;

    &:before {
        content: "";
        display: block;
        top: 0;
        left: -150px;
        position: absolute;
        width: 3000px;
        height: calc(100% + 62px);
        background: ${colors.solidGeomagicDesignx};

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            -webkit-border-top-left-radius: 6px;
            -webkit-border-bottom-left-radius: 6px;
            -moz-border-radius-topleft: 6px;
            -moz-border-radius-bottomleft: 6px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        background: transparent;
        padding: 64px 0 64px 64px;
        &:before {
            content: "";
            display: block;
            top: -62px;
            left: 0;
            position: absolute;
            width: 3000px;
            height: calc(100% + 62px);
            background: ${colors.solidGeomagicDesignx};
        }
    }

    div.review {
        position: relative;
        z-index: 1;
        background: rgba(255, 255, 255, 0.4);

        padding: 24px;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding: 32px;
        }
        border-radius: 16px;
        margin-bottom: 42px;

        box-shadow: 0px 91px 36px rgba(35, 64, 87, 0.01), 0px 51px 31px rgba(35, 64, 87, 0.04), 0px 23px 23px rgba(35, 64, 87, 0.06), 0px 6px 12px rgba(35, 64, 87, 0.07), 0px 0px 0px rgba(35, 64, 87, 0.07);

        img {
            max-height: 24px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                max-height: 32px;
                margin-bottom: 8px;
            }
        }

        h6 {
            color: white;
        }

        p {
            color: white;
            font-family: centrano2-book;
            font-size: 14px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: 18px;
            }
            line-height: 1.67;
            letter-spacing: 0.02em;
            font-weight: 400;

            b {
                font-weight: 800;
            }

        }

        h6 {
            color: white;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: 0.02em;
            font-weight: 400;
            font-family: centrano2-book;

            b {
                font-family: centrano2;
                font-weight: 600;
            }

        }

        h6 {
            margin-top: 12px;
        }


    }


`;
