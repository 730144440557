import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundHalfGrey, BackgroundHalfGreyReverse, BackgroundCircles2 } from '../GlobalStyles';
import { LogoGridV2 } from '../components/V2/LogoGrid-V2/LogoGridV2';
import { TextBlock } from '../components/V2/TextBlock/TextBlock';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { TabbedContent } from '../components/V2/TabbedContent/TabbedContent';
import { ProductSpotlight } from '../components/V2/ProductSpotlight/ProductSpotlight';
import { CustomerStorySpotlight } from '../components/V2/CustomerStorySpotlight/CustomerStorySpotlight';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';

interface Props {
  Lang?: string;
}

const AdditiveTemplate = ({ Lang = 'en', ...props }: Props) => {
  const data = require(`../../data/content/Pages/additive-v2/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const bqData = require(`../../data/content/Pages/additive/build-quality/${Lang}.json`);
  const sitemap = require(`../../data/sitemap-data.json`);

  return (
    <>
      <HelmetContainer currentPage={'additive'} lang={Lang} title={data.helmet.title} />

      <PageContainer>
        <BackgroundGrey>
          <Header demoVertical={'additive'} lang={Lang} />

          <LargeHero
            AddSalesTel={true}
            BackgroundStyle={'image'}
            Copy={data.content.hero.copy}
            Country={Lang}
            Background={'/assets/images/solutions/industrial-additive-manufacturing-solutions.webp'}
            Button={{
              ModelVertical: 'additive',
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
        </BackgroundGrey>
        <BackgroundCircles2>
          <AnnoucementBar
            Content={data.content.annoucementBar.copy}
            Image={'/assets/images/additive/oqton_and_bakerhughes.png'}
            Link={data.content.annoucementBar.link}
          />

          <LogoGridV2
            AdditionalClasses={'reduced-lower-margin'}
            Title={data.content.industryLogos.title}
            Logos={[
              {
                src: '/assets/images/additive/companylogos/addman.png',
                alt: 'Addman'
              },
              {
                src: '/assets/images/additive/companylogos/rafael.png',
                alt: 'Rafael'
              },
              {
                src: '/assets/images/additive/companylogos/bakerhughes.png',
                alt: 'Baker Hughes'
              },
              {
                src: '/assets/images/additive/companylogos/emerson.png',
                alt: 'Emerson'
              },
              {
                src: '/assets/images/additive/companylogos/3dcustom.png',
                alt: '3D Custom Color Printing'
              },
              {
                src: '/assets/images/additive/companylogos/eesa.png',
                alt: 'EESA'
              },
              {
                src: '/assets/images/additive/companylogos/wartsila.png',
                alt: 'Wartsila'
              },
              {
                src: '/assets/images/additive/companylogos/mti.png',
                alt: 'MTI'
              },
              {
                src: '/assets/images/additive/companylogos/pennstate.png',
                alt: 'PennState'
              },
              {
                src: '/assets/images/additive/companylogos/kuleuven.png',
                alt: 'Ku Leuven'
              },
              {
                src: '/assets/images/additive/companylogos/rms.png',
                alt: 'RMS Medical Products'
              },
              {
                src: '/assets/images/additive/companylogos/rodincars.png',
                alt: 'Rodin Cars'
              },
              {
                src: '/assets/images/additive/companylogos/alpine.png',
                alt: 'Alpine'
              },
              {
                src: '/assets/images/additive/companylogos/thales.png',
                alt: 'Thales'
              },
              {
                src: '/assets/images/additive/companylogos/printer-prezz.png',
                alt: 'PrinterPrezz'
              }
            ]}
          />
        </BackgroundCircles2>

        <HighlightBlock
          AdditionalClass={'build-quality-announcement'}
          BlockWidth={'full-width'}
          Theme={'PrimaryGradient'}
          Button={{
            link: sitemap['build-quality'][Lang],
            copy: globalCopy.Buttons.Learn
          }}
          Title={bqData.content.AnnouncementHighlight.Title}
          Copy={bqData.content.AnnouncementHighlight.Copy}
          ImageType={'Split'}
          Image={{
            url: '/assets/2024/images/build-quality/announcement-keyv.png'
          }}
        />

        <BackgroundGrey>
          <TextBlock Title={data.content.copyBlock.title} List={data.content.copyBlock.list} />
          <div className={'spacing-md'}></div>
        </BackgroundGrey>

        <BackgroundHalfGreyReverse>
          <HighlightBlock
            Theme={'Primary'}
            Size={'extraText'}
            Title={data.content.highlightBlock.copy}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: 'additive',
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
            Image={{
              className: '',
              title: '',
              alt: '',
              url: '/assets/images/additive/3DXpert-lattice.webp'
            }}
          />
        </BackgroundHalfGreyReverse>

        <TabbedContent Title={data.content.TabbedContent.Title} Items={data.content.TabbedContent.Items} />

        <BackgroundGrey>
          <ProductSpotlight
            Title={data.content.ProductSpotlight.Title}
            Items={data.content.ProductSpotlight.Products}
          />
        </BackgroundGrey>

        <CustomerStorySpotlight
          Title={data.content.CustomerStories.Title}
          items={data.content.CustomerStories.Items}
          link={data.content.CustomerStories.link}
        />

        <div className={'background-curves'}>
          <img src={'/assets/images/backgrounds/bg-curves.png'} />
        </div>

        <LogoGridV2
          Title={data.content.industryLogos2.title}
          Logos={[
            {
              src: '/assets/images/additive/companylogos/eos.png',
              alt: 'EOS'
            },
            {
              src: '/assets/images/additive/companylogos/renishaw.png',
              alt: 'Reinshaw'
            },
            {
              src: '/assets/images/additive/companylogos/3dsystems.png',
              alt: '3D Systems'
            },
            {
              src: '/assets/images/additive/companylogos/xactmetal.png',
              alt: 'XACT Metal'
            },
            {
              src: '/assets/images/additive/companylogos/trumpf.png',
              alt: 'Trumpf'
            },
            {
              src: '/assets/images/additive/companylogos/hp.png',
              alt: 'HP'
            },
            {
              src: '/assets/images/additive/companylogos/stratasys.png',
              alt: 'Stratasys'
            },
            {
              src: '/assets/images/additive/companylogos/farsoon.png',
              alt: 'Farsoon'
            },
            {
              src: '/assets/images/additive/companylogos/freemelt.png',
              alt: 'Freemelt'
            },
            {
              src: '/assets/images/additive/companylogos/dyemansion.png',
              alt: 'Dye Mansion'
            },
            {
              src: '/assets/images/additive/companylogos/slm.png',
              alt: 'SLM'
            },
            {
              src: '/assets/images/additive/companylogos/dmgmori.png',
              alt: 'DMG Mori'
            },
            {
              src: '/assets/images/additive/companylogos/additiveindustries.png',
              alt: 'Additive Industries'
            },
            {
              src: '/assets/images/additive/companylogos/wematter.png',
              alt: 'Wematter'
            }
          ]}
          LogosGroup2={[
            {
              src: '/assets/images/additive/companylogos/microsoft.png',
              alt: 'Microsoft'
            },
            {
              src: '/assets/images/additive/companylogos/digifabster.png',
              alt: 'DigiFabster'
            },
            {
              src: '/assets/images/additive/companylogos/castor.png',
              alt: 'Castor'
            },
            {
              src: '/assets/images/additive/companylogos/hexagon.png',
              alt: 'Hexagon'
            },
            {
              src: '/assets/images/additive/companylogos/altair.png',
              alt: 'Altair'
            }
          ]}
        />

        <BackgroundHalfGrey>
          <HighlightBlock
            Theme={'Primary'}
            Size={'extraText'}
            Title={data.content.HighlightBlock2.copy}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: 'additive',
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
            Image={{
              className: '',
              title: '',
              alt: '',
              url: '/assets/images/additive/oqton-industrial-additive-manufacturing-solutions.png'
            }}
          />
        </BackgroundHalfGrey>

        <BackgroundGrey>
          <Accordion Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
        </BackgroundGrey>
      </PageContainer>
      <ArticleBlock
        MostRecentBlogs={true}
        Title={data.content.Stories.Title}
        Layout={'layout-3'}
        Topic={'additive-manufacturing'}
      />

      <Footer lang={Lang} />
    </>
  );
};

export default AdditiveTemplate;
