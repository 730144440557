import React, { useState } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ComponentStyles, StyledSlides, StyledModal } from './styles.jsx';
import { ButtonComponent } from '../../V2/Button/Button';
import { ShowVideo } from '../../V2/Grid/GridItems/GridItemHelper';

interface Props {
  title?: string;
  copy?: string;
  slides?: object;
}

interface SlideProps {
  itemKey?: string;
  img?: string;
  title?: string;
  copy?: string;
  image?: string;
  cta?: object;
  video?: string;
  openModelFunction: any;
}

const NextArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={'/assets/icons/slider-arrow-red.png'} />
    </div>
  );
};

const PrevArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={'/assets/icons/slider-arrow-red.png'} />
    </div>
  );
};

const Modal = ({ closeModelFunction, video }) => {
  return (
    <StyledModal className={'modal'}>
      <div
        className={'hidden-close'}
        onClick={() => {
          closeModelFunction();
        }}
      ></div>
      <div className={'modal-content'}>
        <span
          className={'close'}
          onClick={() => {
            closeModelFunction();
          }}
        >
          <img src={'/assets/icons/close.svg'} />
        </span>
        {ShowVideo(video)}
      </div>
    </StyledModal>
  );
};

const Slide = ({ title, cta, copy, img, video, openModelFunction, itemKey }: SlideProps) => {
  return (
    <StyledSlides className={`slide-style-key-${itemKey}`}>
      <img src={img} />
      <div className={'text-wrap'}>
        <h1>{title}</h1>
        <p>{copy}</p>
        <ButtonComponent
          {...cta}
          ClickFunction={() => {
            openModelFunction(video);
          }}
        />
      </div>
    </StyledSlides>
  );
};

export const FeatureSlider = ({ title, copy, slides, ...props }: Props) => {
  const [modalActive, setModalActive] = useState(false);
  const [activeVideo, setActiveVideo] = useState(false);
  const closeModal = () => {
    setModalActive(false);
    setActiveVideo(false);
  };

  const openModal = video => {
    setModalActive(true);
    setActiveVideo(video);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />, // Custom next arrow
    prevArrow: <PrevArrow /> // Custom previous arrow
  };

  return (
    <ComponentStyles {...props}>
      <div className={'container'}>
        <h2>{title}</h2>
        <p>{copy}</p>
        <hr className={'divider'} />
        <Slider {...settings}>
          {slides.map((item, index) => (
            <Slide key={index} itemKey={index} {...item} openModelFunction={openModal} />
          ))}
        </Slider>
      </div>
      {modalActive && <Modal video={activeVideo} closeModelFunction={closeModal} />}
    </ComponentStyles>
  );
};
