import React from 'react';
import { createMarkUp } from '../../helpers';
import { ComponentStyles, ReviewContainer } from './styles.jsx';

interface Props {
  title?: string;
  reviews?: object;
  theme?: string;
}

export const Reviews = ({ title, reviews, theme, ...props }: Props) => {
  return (
    <ComponentStyles className={'Component-Reviews'} {...props}>
      <div className={'container'}>
        <div>
          <h2 dangerouslySetInnerHTML={createMarkUp(title)}></h2>
          <hr className={'divider'} />
        </div>

        <ReviewContainer>
          {reviews.map((review, index) => (
            <div className={'review'} key={index}>
              <img src={`/assets/logos/${review.logo}.svg`} />
              <p dangerouslySetInnerHTML={createMarkUp(review.copy)}></p>
              <h6 dangerouslySetInnerHTML={createMarkUp(review.author)}></h6>
            </div>
          ))}
        </ReviewContainer>
      </div>
    </ComponentStyles>
  );
};
