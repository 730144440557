import React from 'react';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { ResourceLinks } from '../components/V2/ResourceLinks/ResourceLinks';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { BackgroundWave } from '../GlobalStyles';
import { LogoGrid } from '../components/V2/LogoGrid/LogoGrid';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
import moment from 'moment/moment';

const ThreeDInspectionSolution = () => {
  const AnnoucementEndDate = moment('2023-12-12 17:00:00');
  const shouldDisplayAnnouncement = moment().isBefore(AnnoucementEndDate);
  const vertical = '3d-inspection';

  let additionalClasses = '';
  if (shouldDisplayAnnouncement) {
    additionalClasses = 'additional-padding-top';
  }

  return (
    <>
      <HelmetContainer
        currentPage={'3d-inspection'}
        title="3D Inspection Software | Oqton"
        description={`Lower the barrier to entry of scan-based metrology with Geomagic 3D inspection software, developed by Oqton.`}
      />

      <PageContainer>
        <BackgroundWave>
          <Header demoVertical={vertical} lang={'en'} />
          <LargeHero
            AddSalesTel={true}
            BackgroundStyle={'image'}
            Copy={
              '<h1><b>3D Inspection Software</b></h1><p>Accessible scan-based 3D inspection, enabling more people in more production environments to improve their manufacturing processes.</p>'
            }
            Background={'/assets/images/solutions/inspection-key-visual-v2.webp'}
            Button={{
              ModelVertical: vertical,
              copy: 'Contact Us',
              OpenDemoModel: true,
              theme: 'accent-red'
            }}
          />

          <div className={'wrap'}>
            <AnnoucementBar
              AdditionalClass={'offset-bottom increase-z header-margin-0'}
              Image={'https://oqtonadmin.com/wp-content/uploads/2024/03/aerospace-annoucement.png'}
              Content={
                '<h3>From Physical Part to Parametric Model: 3D Scanning Applications in Aerospace</h3><p>Download this whitepaper and discover how companies across the Aerospace industry are leveraging 3D Scanning to design and manufacture better parts, faster</p>'
              }
              Link={{
                label: 'Download whitepaper',
                target: '_blank',
                link: 'https://oqton.com/news/ebooks/3d-scanning-applications-in-aerospace/'
              }}
            />
          </div>
        </BackgroundWave>

        <TextColumns
          AdditionalClasses={'annoucement-top'}
          Theme={'dark'}
          Columns={'1'}
          Title={`We're lowering the barrier to entry of scan-based 3D inspection`}
          Copy={
            '<p>Engineers and quality assurance managers from all industries are tapping into scan-based 3D inspection for its unmatched accuracy, flexibility and speed. 3D inspection software delivers faster time-to-market, improved part quality and huge process sustainability gains.<br/><br/>Using <a href="/3d-scanning-solutions/">3D scanning</a> technologies to capture point-cloud data allows you to quickly catalogue and inspect any parts being manufactured in your production environment. Optimizing this quality process is going to yield huge cost savings, reduced scrappage and huge process sustainability gains.</p>'
          }
          Button={{
            copy: 'How does 3D scanning accelerate inspection?',
            theme: 'white-outline',
            link: 'https://oqton.com/posts/how-does-3d-scanning-accelerate-inspection/'
          }}
        />

        <LogoGrid
          Title={'Trusted by industry partners'}
          Copy={'Click each logo to learn more'}
          Logos={[
            {
              src: '/assets/images/partners/partner-logos/faro.png',
              alt: 'Faro',
              link: '/partners/faro/'
            },
            {
              src: '/assets/images/partners/partner-logos/evatronix.png',
              alt: 'Evatronix',
              link: '/partners/evatronix/'
            },
            {
              src: '/assets/images/partners/partner-logos/creaform.png',
              alt: 'Creaform',
              link: '/partners/creaform/'
            },
            {
              src: '/assets/images/partners/partner-logos/shining3d.png',
              alt: 'Shining3d',
              link: '/partners/shining-3d/'
            },
            {
              src: '/assets/images/partners/partner-logos/artec3d.png',
              alt: 'artec3d',
              link: '/partners/artec-3d/'
            },
            {
              src: 'https://i0.wp.com/oqtonadmin.com/wp-content/uploads/2023/10/Scantech-1.png',
              alt: 'scantech',
              link: '/partners/scantech/'
            },
            {
              src: '/assets/images/partners/partner-logos/kreon.png',
              alt: 'kreon',
              link: '/partners/kreon/'
            },
            {
              src: '/assets/images/partners/partner-logos/nikon.png',
              alt: 'nikon',
              link: '/partners/nikon/'
            },
            {
              src: '/assets/images/partners/partner-logos/hexagon.png',
              alt: 'hexagon',
              link: '/partners/hexagon/'
            },
            {
              src: '/assets/images/partners/partner-logos/smartech3d.png',
              alt: 'hexagon',
              link: '/partners/smarttech3d/'
            }
          ]}
        />

        <SplitColumn
          ImagePosition={'Left'}
          Logo={'geomagic-controlx'}
          Copy={
            "<p><a href='/geomagic-controlx/'>Geomagic Control X</a> is our professional metrology software, enabling you to capture and process data from 3D scanners and other devices to measure, understand, and communicate inspection results. We're making it simpler for you to implement accurate, <a href='/geomagic-controlx/automated-inspection/'>automated Quality Assurance</a> in your production environment.</p><br/><p><a href='/campaigns/geomagic/essentials-bundle/'>Control X Essentials</a> is a streamlined subset of Control X functionality lowers the barrier-to-entry for precise 3D metrology tools, regardless of your budget, project frequency, or inspection background.</p>"
          }
          Background={'Off-White'}
          MediaType={'Video'}
          Video={'https://play.vidyard.com/NnrFHQJxKs2PuVb55Nexfq.html'}
          Button={{
            copy: 'Learn More',
            theme: 'white-outline',
            link: '/geomagic-controlx'
          }}
        />

        <ResourceLinks
          Title={'Resources'}
          Copy={'Check out some of our great 3D Inspection content'}
          Button={{
            Label: 'Show All Resources',
            Link: '/news'
          }}
          Items={[
            {
              category: 'Whitepaper',
              title: 'Is 3D Scanning Right for my Inspection Needs?',
              link: {
                target: '_blank',
                url: '/news/ebooks/is-3d-scanning-right-for-my-inspection-needs/',
                copy: 'Read Whitepaper'
              }
            },
            {
              category: 'Blog',
              title: 'Introduction to First Article Inspection',
              link: {
                target: '_blank',
                url: '/posts/introduction-to-first-article-inspection/',
                copy: 'Read blog'
              }
            }
          ]}
        />
        <Footer />
      </PageContainer>
    </>
  );
};

export default ThreeDInspectionSolution;
