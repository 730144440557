import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`
    padding: 36px 0;
    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 120px 0;
    }

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.3;
        letter-spacing: 0.01em;
        text-align: center;
        margin-bottom: 16px;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 48px;
        }
    }

    p {
        text-align: center;
        font-family: centrano2-book;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0.02em;
        color: #234057;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 32px;

        }
    }

    hr.divider {
        margin: 24px auto;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            margin: 36px auto 42px;
        }
    }

    .slick-slider {

        .slick-arrow {
            display: none;
            width: 50px;
            height: 50px;
            z-index: 20;
            top: calc(50% - 25px);

            img {
                width: 50px;
            }

            &:before {
                display: none;
            }

            &.slick-prev {

            }

            &.slick-next {
                img{
                    transform: rotate(180deg);
                }
            }

            @media (min-width: ${mediaQueriesSizes.md}px) {
                display: block;

            }
        }

        .slick-dots {
            display: block;
            margin-top: 24px;
            position: relative;
            bottom: 0;

            li {
                button {
                    &:before {
                        font-size: 12px;
                        opacity: 0.1;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            opacity: 0.5;
                            color: ${colors.solidGeomagicDesignx};
                        }
                    }
                }
            }
        }
    }

`;

export const StyledModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(35, 64, 87, 0.5);
    width: 100%;
    height: 100%;
    z-index: 50;
    
    display: flex;
    align-items: center;
    align-content: center;

    .modal-content {
        margin: 0 auto;
        width: 800px;
        background: white;
        border: 2.21755px solid #EBE8E5;
        box-shadow: 0px 5.91348px 23.6539px rgba(233, 230, 226, 0.3);
        border-radius: 5.91348px;
        position: relative;
        padding: 62px 32px 32px;
        span.close {
            position: absolute;
            width: 32px;
            height: 32px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 24px;
            right: 24px;
            background: transparent;
            border-radius: 6px;
            cursor: pointer;
            transition: 0.25s;
            img {
                margin: 0;
                margin-right: 0;
                position: relative;
            }

            &:hover {
                /* UI-Light/Stack Accent */
                background: ${colors.UILightStackAccent}
            }
        }

    }

    .hidden-close{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }

`;

export const StyledSlides = styled.div`

    background: linear-gradient(0deg, #FFFFFF 0.01%, rgba(255, 255, 255, 0.2) 0.02%, #FFFFFF 100%);
    border: 2.21755px solid #EBE8E5;
    box-shadow: 0px 5.91348px 23.6539px rgba(233, 230, 226, 0.3);
    border-radius: 5.91348px;
    max-height: 80vh;
    position: relative;
    height: 350px;
    overflow: hidden;
    @media (min-width: ${mediaQueriesSizes.md}px) {
        height: 400px;
        max-height: none;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        height: 550px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        height: 700px;
    }

    img {
        //pointer-events: none;
        //display: none !important;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            display: block !important;;
        }
    }

    .text-wrap {
        padding: 16px;
        width: 85%;
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            width: 75%;
        }
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding: 32px 0 32px 32px;
            width: 55%;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding: 48px 0 48px 48px;
            width: 60%;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            padding: 100px 0 100px 100px;
            width: 51%;
        }

        text-align: left;

        h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 1.25;
            letter-spacing: 0.01em;
            color: ${colors.primary};
            margin-bottom: 12px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                margin-bottom: 24px;
                font-size: 42px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                margin-bottom: 24px;
                font-size: 48px;
            }
        }

        p {
            font-family: centrano2-book;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.6;
            letter-spacing: 0.01em;
            color: ${colors.UILightSecondary};
            text-align: left;
            text-wrap: balance;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                font-size: 18px;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: 22px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                font-size: 26px;
            }
        }

        a.custom-button {
            margin-top: 24px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                margin-top: 42px;
            }
        }
    }

    &.slide-style-key-0 {
        img {
            position: absolute;
            width: auto;
            height: auto;
            bottom: 0;
            right: 0;
            max-width: 50%;
            @media (min-width: ${mediaQueriesSizes.sm}px) {
                max-width: 65%;
            }
            @media (min-width: ${mediaQueriesSizes.md}px) {
                max-width: 400px;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                max-width: 550px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                max-width: 800px;
            }
        }
    }

    &.slide-style-key-1 {
        img {
            position: absolute;
            width: auto;
            height: auto;
            top: 20%;
            right: -80px;
            max-width: 50%;
            @media (min-width: ${mediaQueriesSizes.sm}px) {
                top: 10%;
                right: -35px;
            }
            @media (min-width: ${mediaQueriesSizes.md}px) {
                top: 8%;
                right: -15px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                top: 10%;
                right: 0;
                max-width: 50%;
            }

        }
    }

    &.slide-style-key-2 {
        img {
            position: absolute;
            width: auto;
            height: auto;
            bottom: 15px;
            right: 10px;
            max-width: 75%;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                bottom: 25px;
                right: 25px;
                max-width: 420px;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                bottom: 25px;
                right: 25px;
                max-width: 470px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                bottom: 95px;
                right: 25px;
                max-width: 680px;
            }
            @media (min-width: ${mediaQueriesSizes.xxl}px) {
                bottom: 80px;
                right: 100px;
                max-width: 820px;
            }

        }
    }

    &.slide-style-key-3 {
        img {
            position: absolute;
            width: auto;
            height: auto;
            bottom: 0;
            right: -130px;
            max-width: 320px;
            @media (min-width: ${mediaQueriesSizes.sm}px) {
                right: -50px;
                max-width: 290px;
            }
            @media (min-width: ${mediaQueriesSizes.md}px) {
                right: 0;
                max-width: 480px;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                max-width: 550px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                max-width: 780px;
            }

        }
    }

    &.slide-style-key-4 {
        img {
            position: absolute;
            width: auto;
            height: auto;
            bottom: 0px;
            right: -120px;
            max-width: 310px;

            @media (min-width: ${mediaQueriesSizes.sm}px) {
                right: -150px;
                max-width: 420px;
            }
            @media (min-width: ${mediaQueriesSizes.md}px) {
                max-width: 550px;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                max-width: 750px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                max-width: 900px;
                right: -50px;
            }
        }
    }

    &.slide-style-key-5 {
        img {
            position: absolute;
            width: auto;
            height: 100%;
            bottom: 0px;
            right: -24%;
            width: auto;
            z-index: -1;
            opacity: 0.5;

            @media (min-width: ${mediaQueriesSizes.md}px) {
                right: 0;
                width: auto;
                z-index: 0;
                opacity: 1;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                right: -70px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                right: 0;
            }
        }
    }
}

`;