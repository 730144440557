import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AdditonalButtons,
  ButtonWrap,
  HeaderOffset,
  HeaderPageCover,
  HeaderWrap,
  LangBtn,
  LanguageDropdown,
  LangSelector,
  DesktopLinks,
  MobileBurgerMenu,
  MobileMenuWrapper,
  PopUpWrapStyles,
  PopUpLanguage,
  PopUpPhone,
  TabletProductsMenu,
  TabletSolutionsMenuWrapper,
  NavWrap,
  ProductsMenu,
  SolutionsMenu,
  IndustriesMenuStyles,
  TabletIndustriesMenuWrapper
} from './styles.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/fontawesome-free-solid';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';

interface Props {
  lang?: string;
  demoVertical?: string;
  demoTitle?: string;
  inverted?: boolean;
  hideNavigation?: boolean;
  whiteStripped?: boolean;
}

export const StickyHeaderNavigation = ({
  inverted,
  hideNavigation,
  lang,
  demoVertical,
  whiteStripped = false,
  demoTitle,
  ...props
}: Props) => {
  if (!lang) {
    lang = 'en';
  }

  const globalData = require(`../../../../data/content/Global/${lang}.json`);
  const langData = require(`../../../../data/langs.json`);
  const location = typeof window !== 'undefined' ? useLocation() : {};
  const navigate = useNavigate();

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openSolutionsMenu, setOpenSolutionsMenu] = useState(false);
  const [openIndustriesMenu, setOpenIndustriesMenu] = useState(false);
  const [openProductsMenu, setOpenProductsMenu] = useState(false);
  const [openLangMobileMenu, setOpenLangMobileMenu] = useState(false);
  const [openTelMobileMenu, setOpenTelMobileMenu] = useState(false);

  const handleToggleMobileMenu = () => {
    setOpenLangMobileMenu(false);
    setOpenTelMobileMenu(false);

    if (openMobileMenu) {
      setOpenSolutionsMenu(false);
      setOpenProductsMenu(false);
    }
    setOpenMobileMenu(!openMobileMenu);
  };

  const handleToggleSolutionsMobileMenu = () => {
    setOpenProductsMenu(false);
    setOpenLangMobileMenu(false);
    setOpenTelMobileMenu(false);
    setOpenIndustriesMenu(false);
    setOpenSolutionsMenu(!openSolutionsMenu);
  };

  const handleToggleIndustriesMobileMenu = () => {
    setOpenProductsMenu(false);
    setOpenLangMobileMenu(false);
    setOpenTelMobileMenu(false);
    setOpenSolutionsMenu(false);
    setOpenIndustriesMenu(!openIndustriesMenu);
  };

  const handleToggleProductsMenu = () => {
    setOpenSolutionsMenu(false);
    setOpenLangMobileMenu(false);
    setOpenTelMobileMenu(false);
    setOpenIndustriesMenu(false);
    setOpenProductsMenu(!openProductsMenu);
  };

  const handleToggleLangMenu = (screenSize = 'desktop') => {
    // if (screenSize !== 'mobile') {
    //   setOpenMobileMenu(false);
    // }
    setOpenSolutionsMenu(false);
    setOpenProductsMenu(false);
    setOpenIndustriesMenu(false);
    setOpenTelMobileMenu(false);
    setOpenLangMobileMenu(!openLangMobileMenu);
  };

  const handleToggleTelMenu = (screenSize = 'desktop') => {
    // if (screenSize === 'mobile') {
    //   setOpenMobileMenu(false);
    // }
    setOpenSolutionsMenu(false);
    setOpenProductsMenu(false);
    setOpenIndustriesMenu(false);
    setOpenLangMobileMenu(false);
    if (typeof window !== 'undefined') {
      navigate(location.pathname, { replace: true });
    }
    setOpenTelMobileMenu(!openTelMobileMenu);
  };

  const SolutionsMenuPart1 = () => {
    return (
      <li>
        <a href={globalData.Navigation.SolutionsSubMenu[0].fixedUrl}>
          {globalData.Navigation.SolutionsSubMenu[0].title}
        </a>
        <ul>
          <li>
            <a href={globalData.Navigation.SolutionsSubMenu[1].fixedUrl}>
              {globalData.Navigation.SolutionsSubMenu[1].title}
            </a>
          </li>
          {lang === 'en' && (
            <li className={'polymers-link'}>
              <ul>
                <li>
                  <a href="/additive/polymers/">Polymer printing</a>
                </li>
              </ul>
            </li>
          )}
          {lang === 'tr' && (
            <li className={'polymers-link'}>
              <ul>
                <li>
                  <a href="/tr/eklemeli/polymers/">Polymer printing</a>
                </li>
              </ul>
            </li>
          )}
          <li>
            <a href={globalData.Navigation.SolutionsSubMenu[2].fixedUrl}>
              {globalData.Navigation.SolutionsSubMenu[2].title}
            </a>
          </li>
        </ul>
      </li>
    );
  };

  const IndustriesMenu = () => {
    return (
      <>
        {globalData.Navigation.IndustriesSubMenu.map((item, index) => (
          <li key={index}>
            <a href={item.fixedUrl}>{item.title}</a>
          </li>
        ))}
      </>
    );
  };

  const SolutionsMenuPart2 = () => {
    return (
      <>
        <li>
          <a href={globalData.Navigation.SolutionsSubMenu[3].fixedUrl}>
            {globalData.Navigation.SolutionsSubMenu[3].title}
          </a>
          <ul>
            <li>
              <a href={globalData.Navigation.SolutionsSubMenu[4].fixedUrl}>
                {globalData.Navigation.SolutionsSubMenu[4].title}
              </a>
            </li>
            <li>
              <a href={globalData.Navigation.SolutionsSubMenu[5].fixedUrl}>
                {globalData.Navigation.SolutionsSubMenu[5].title}
              </a>
            </li>
          </ul>
        </li>
      </>
    );
  };

  const ProductsMenuGeomagicPart1 = () => {
    return (
      <>
        <a href={globalData.Navigation.ProductURLs.geoDesignX}>
          <ProductLogo Logo={'geomagic-designx'} Size={'sm'} />
        </a>
        <a href={globalData.Navigation.ProductURLs.geoControlX}>
          <ProductLogo Logo={'geomagic-controlx'} Size={'sm'} />
        </a>
        <a href={globalData.Navigation.ProductURLs.geoWrap}>
          <ProductLogo Logo={'geomagic-wrap'} Size={'sm'} />
        </a>
        <a href={globalData.Navigation.ProductURLs.GeomagicForSolidworks}>
          <ProductLogo Logo={'geomagic-for-solid'} Size={'sm'} />
        </a>
      </>
    );
  };

  const ProductsMenuGeomagicPart2 = () => {
    return <></>;
  };

  const ProductsMenuNoneGeomagic = () => {
    return (
      <>
        <a href={globalData.Navigation.ProductURLs.manufacturingOs}>
          <ProductLogo Logo={'manufacturing-os'} Size={'sm'} />
        </a>
        <a href={globalData.Navigation.ProductURLs.Threedxpert}>
          <ProductLogo Logo={'3dxpert'} Size={'sm'} />
        </a>
        <a href={globalData.Navigation.ProductURLs.geoFreeeform}>
          <ProductLogo Logo={'geomagic-freeform'} Size={'sm'} />
        </a>
        <a href={globalData.Navigation.ProductURLs.d2p}>
          <ProductLogo Logo={'d2p'} Size={'sm'} />
        </a>
      </>
    );
  };

  const CloseAllMenus = () => {
    setOpenMobileMenu(false);
    setOpenSolutionsMenu(false);
    setOpenProductsMenu(false);
    setOpenLangMobileMenu(false);
    setOpenTelMobileMenu(false);
  };

  const anyMenuOpen =
    openMobileMenu || openSolutionsMenu || openProductsMenu || openLangMobileMenu || openTelMobileMenu;

  const UpdatedLanguagePopUp = () => {
    if (openLangMobileMenu) {
      return (
        <PopUpWrapStyles>
          <PopUpLanguage>
            <button className={'close'} onClick={handleToggleLangMenu}>
              <img src={'/assets/icons/close.svg'} />
            </button>

            {langData.map((item, index) => {
              if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
                return (
                  <a href={item.fixedURL + item.url} hrefLang={item.languageCode} key={index}>
                    <img alt="" src={`/assets/icons/flags/rectangular/${item.languageCode}.png`} /> {item.nativeLabel}
                  </a>
                );
              } else {
                if (item.languageCode === 'cn') {
                  return (
                    <a
                      className={lang === item.languageCode ? 'checked' : ''}
                      href={item.fixedURL + item.url}
                      hrefLang={item.languageCode}
                      key={index}
                    >
                      <img alt="" src={`/assets/icons/flags/rectangular/${item.languageCode}.png`} /> {item.nativeLabel}
                      {lang === item.languageCode && <FontAwesomeIcon icon={'check'} />}
                    </a>
                  );
                } else {
                  return (
                    <a
                      className={lang === item.languageCode ? 'checked' : ''}
                      href={item.url}
                      hrefLang={item.languageCode}
                      key={index}
                    >
                      <img alt="" src={`/assets/icons/flags/rectangular/${item.languageCode}.png`} /> {item.nativeLabel}
                      {lang === item.languageCode && <FontAwesomeIcon icon={'check'} />}
                    </a>
                  );
                }
              }
            })}
          </PopUpLanguage>
        </PopUpWrapStyles>
      );
    }
  };
  const UpdatedPhonePopUp = () => {
    if (openTelMobileMenu) {
      return (
        <PopUpWrapStyles>
          <PopUpPhone>
            <button className={'close'} onClick={handleToggleTelMenu}>
              <img src={'/assets/icons/close.svg'} />
            </button>
            <section className={'main'}>
              <h3>{globalData.Navigation.PhonePopUp.title}</h3>
              <p>{globalData.Navigation.PhonePopUp.copy}</p>

              <div className={'grid'}>
                {globalData.phoneNumbers.map((item, index) => {
                  return (
                    <a className={'tel-number'} href={'tel:' + item.phone} key={index}>
                      <img alt="" src={`/assets/icons/flags/rectangular/${item.titleShort.toLowerCase()}.png`} />{' '}
                      {item.titleShort}: <span>{item.phone}</span>
                    </a>
                  );
                })}
              </div>
            </section>
            <section className={'support'}>
              <div>
                <h3>{globalData.Navigation.PhonePopUp.support.title}</h3>
                <p>{globalData.Navigation.PhonePopUp.support.copy}</p>
              </div>
              <a target={'_blank'} href={'https://softwaresupport.oqton.com/s/article/Contact-Us?language=en_US'}>
                {globalData.Navigation.PhonePopUp.support.link}
              </a>
            </section>
          </PopUpPhone>
        </PopUpWrapStyles>
      );
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && location.hash === '#openPhone') {
      setOpenTelMobileMenu(true);
    }
  }, [location]);

  return (
    <>
      {anyMenuOpen && <HeaderPageCover onClick={CloseAllMenus}></HeaderPageCover>}

      <UpdatedLanguagePopUp />
      <UpdatedPhonePopUp />

      <HeaderOffset></HeaderOffset>
      <HeaderWrap headerStyle={whiteStripped ? 'noBG' : undefined}>
        <div className={'container'}>
          <NavWrap>
            {!whiteStripped && (
              <MobileBurgerMenu onClick={handleToggleMobileMenu} className={openMobileMenu ? 'open-menu' : ''}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </MobileBurgerMenu>
            )}
            <a href={globalData.Navigation.HomeURL}>
              {whiteStripped ? (
                <img className={'logo'} src={`/assets/images/OqtonLogo.svg`} alt="Oqton.com" />
              ) : (
                <img
                  className={'logo'}
                  src={inverted && !isOpen ? `/assets/images/OqtonLogo.svg` : `/assets/images/DarkOqton.svg`}
                  alt="Oqton.com"
                />
              )}
            </a>
            {!whiteStripped && (
              <DesktopLinks>
                <li className={'link-w-drop'}>
                  {globalData.Navigation.Solutions} <FontAwesomeIcon icon={faChevronDown} />
                  <SolutionsMenu className={'solutions'}>
                    <SolutionsMenuPart1 />
                    <SolutionsMenuPart2 />
                  </SolutionsMenu>
                </li>
                <li className={'link-w-drop'}>
                  {globalData.Navigation.Products} <FontAwesomeIcon icon={faChevronDown} />
                  <ProductsMenu className={'products'}>
                    <li>
                      <ProductsMenuNoneGeomagic />
                    </li>
                    <li>
                      <ProductsMenuGeomagicPart1 />
                      <ProductsMenuGeomagicPart2 />
                    </li>
                  </ProductsMenu>
                </li>
                <li className={'link-w-drop'}>
                  {globalData.Navigation.Industries} <FontAwesomeIcon icon={faChevronDown} />
                  <IndustriesMenuStyles className={'solutions'}>
                    <IndustriesMenu />
                  </IndustriesMenuStyles>
                </li>
                <li>
                  <a href={'/news/'}>{globalData.Navigation.News}</a>
                </li>
                <li>
                  <a href={'/events/'}>{globalData.Navigation.Events}</a>
                </li>
                <li>
                  <a target="_blank" href={`https://softwaresupport.oqton.com/s/?language=${lang}`}>
                    {globalData.Navigation.Support}
                  </a>
                </li>
              </DesktopLinks>
            )}
          </NavWrap>
          <AdditonalButtons>
            {!whiteStripped ? (
              <>
                <LanguageDropdown onClick={handleToggleLangMenu}>
                  <LangBtn>
                    <img alt={`${lang}`} className="primary" src={`/assets/icons/flags/circlular/${lang}.png`} />
                    <span>{lang}</span>
                  </LangBtn>
                </LanguageDropdown>
                <LanguageDropdown onClick={handleToggleTelMenu}>
                  <LangBtn className={'phone'}>
                    <img alt="" src={'/assets/icons/phone.svg'} />
                  </LangBtn>
                </LanguageDropdown>
                <ButtonWrap>
                  <ButtonComponent
                    ID={'TopNav-Demo-Btn'}
                    Label={globalData.Buttons.RequestDemo}
                    OpenDemoModel={true}
                    ModelVertical={demoVertical}
                    ModelTitle={demoTitle}
                    Theme={'accent-teal'}
                  />
                </ButtonWrap>
              </>
            ) : (
              <ButtonWrap>
                <ButtonComponent
                  Label={`${globalData.phoneNumbers[0].phone}`}
                  Icon={'phone'}
                  Link={`tel:${globalData.phoneNumbers[0].phone}`}
                  Theme={'transparent-with-white-outline'}
                />
              </ButtonWrap>
            )}
          </AdditonalButtons>
        </div>
        {!whiteStripped && (
          <>
            <MobileMenuWrapper className={openMobileMenu ? 'open-menu' : ''}>
              <div className={'container'}>
                <ul>
                  <li onClick={handleToggleSolutionsMobileMenu} className={openSolutionsMenu ? 'open' : 'close'}>
                    <span>
                      {globalData.Navigation.Solutions} <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <ul className={openSolutionsMenu ? 'solutions-menu open-menu' : 'solutions-menu'}>
                      <SolutionsMenuPart1 />
                      <SolutionsMenuPart2 />
                    </ul>
                  </li>
                  <li onClick={handleToggleProductsMenu} className={openProductsMenu ? 'open' : 'close'}>
                    <span>
                      {globalData.Navigation.Products} <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <ul className={openProductsMenu ? 'products-menu open-menu' : 'products-menu'}>
                      <ProductsMenuNoneGeomagic />
                      <ProductsMenuGeomagicPart1 />
                      <ProductsMenuGeomagicPart2 />
                    </ul>
                  </li>
                  <li onClick={handleToggleIndustriesMobileMenu} className={openIndustriesMenu ? 'open' : 'close'}>
                    <span>
                      {globalData.Navigation.Industries} <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <ul className={openIndustriesMenu ? 'industries-menu open-menu' : 'industries-menu'}>
                      <IndustriesMenu />
                    </ul>
                  </li>
                  <li>
                    <a href={'/news/'}>{globalData.Navigation.News}</a>
                  </li>
                  <li>
                    <a href={'/events/'}>{globalData.Navigation.Events}</a>
                  </li>
                  <li>
                    <a href={'https://softwaresupport.oqton.com/s/'}>{globalData.Navigation.Support}</a>
                  </li>
                </ul>

                <div className={'menu-buttons'}>
                  <LangBtn
                    className="mobile-lang-btn"
                    onClick={() => {
                      handleToggleLangMenu('mobile');
                    }}
                  >
                    {langData.map((item, index) => {
                      if (lang === item.languageCode) {
                        return (
                          <img
                            key={index}
                            className={'flag'}
                            alt=""
                            src={`/assets/icons/flags/circlular/${item.languageCode}.png`}
                          />
                        );
                      }
                    })}

                    <span>{globalData.Navigation.LangChange}</span>
                  </LangBtn>

                  <LangBtn className={'mobile-lang-btn phone'} onClick={handleToggleTelMenu}>
                    <img alt="" src={'/assets/icons/phone.svg'} />
                    <span>{globalData.Navigation.PhonePopUp.mobileBtn}</span>
                  </LangBtn>
                </div>
              </div>
            </MobileMenuWrapper>
            <TabletSolutionsMenuWrapper className={openSolutionsMenu ? 'open-menu' : ''}>
              <div className={'container'}>
                <ul>
                  <SolutionsMenuPart1 />
                </ul>
                <div className={'divider'}></div>
                <ul>
                  <SolutionsMenuPart2 />
                </ul>
              </div>
            </TabletSolutionsMenuWrapper>
            <TabletIndustriesMenuWrapper className={openIndustriesMenu ? 'open-menu' : ''}>
              <div className={'container'}>
                <ul>
                  <IndustriesMenu />
                </ul>
              </div>
            </TabletIndustriesMenuWrapper>
            <TabletProductsMenu className={openProductsMenu ? 'open-menu' : ''}>
              <div className={'container'}>
                <div>
                  <ProductsMenuNoneGeomagic />
                </div>
                <div>
                  <ProductsMenuGeomagicPart1 />
                </div>
                <div>
                  <ProductsMenuGeomagicPart2 />
                </div>
              </div>
            </TabletProductsMenu>
          </>
        )}
      </HeaderWrap>
    </>
  );
};
