import React, { useEffect, useState } from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import {
  BackgroundBlue,
  BackgroundGrey,
  BackgroundHalfGreyReverse,
  BackgroundWhite,
  BackgroundHalfBlue
} from '../GlobalStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { HighlightBlockRightImage } from '../components/V2/HighlightBlocks/HighlightBlockRightImage/HighlightBlockRightImage';
import { TextBlock } from '../components/V2/TextBlock/TextBlock';
import { IconGrid } from '../components/V2/IconGrid/IconGrid';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { LogoGridV2 } from '../components/V2/LogoGrid-V2/LogoGridV2';
import { ProductSpotlight } from '../components/V2/ProductSpotlight/ProductSpotlight';

interface Props {
  Lang?: string;
}

const EnergyTemplate = ({ Lang = 'en', wordpressData = null, ...props }: Props) => {
  let globalCopy = require(`../../data/content/Global/${Lang}.json`);

  console.log(wordpressData);

  const vertical = 'energy';

  if (wordpressData != null) {
    return (
      <>
        <HelmetContainer
          currentPage={'energy'}
          image={wordpressData.fields.seo.page_share_image}
          title={wordpressData.fields.seo.page_title}
          pageDescription={wordpressData.fields.seo.page_meta_description}
          description={wordpressData.fields.seo.page_meta_description}
        />

        <PageContainer lang={Lang}>
          <Header demoVertical={vertical} lang={Lang} />

          <LargeHero
            Country={Lang}
            AddSalesTel={wordpressData.fields.hero.hero_.add_sales_number}
            BackgroundStyle={'image'}
            Copy={`<h1><b>${wordpressData.fields.hero.hero_.title}</b></h1>${wordpressData.fields.hero.hero_.copy}`}
            Background={wordpressData.fields.hero.hero_.image}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.ContactUs,
              OpenDemoModel: true
            }}
          />

          <BackgroundHalfBlue>
            <div className={'wrap'}>
              <AnnoucementBar
                AdditionalClass={'offset-bottom'}
                Image={'https://oqtonadmin.com/wp-content/uploads/2024/02/Baker-Hughes-1.png'}
                Content={
                  '<p><i>“Our agreement with Oqton will support the industrialization of our additive manufacturing and help drive more intelligent operations for the energy and industrial segments”</i><br/><b>Jim Apostolides</b> - SVP Enterprise Operational Excellence</p>'
                }
              />
              <div className={'spacing-xl'}></div>
              <div className={'spacing-sm'}></div>
            </div>
          </BackgroundHalfBlue>

          <HighlightBlockRightImage
            AdditionalClass={'decreased-padding'}
            Theme={'dark-teal'}
            ImageType={'Background'}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/energy/energy-outline.png'
            }}
            Title={wordpressData.fields.highlight_block_one.title}
            Copy={wordpressData.fields.highlight_block_one.copy}
          />

          <TextBlock Title={wordpressData.fields.text_block.title} Copy={wordpressData.fields.text_block.copy} />

          <IconGrid
            id={'applications'}
            Theme={'light'}
            Title={wordpressData.fields.benefits_section.title}
            Items={wordpressData.fields.benefits_section.grid_items}
          />

          <TextColumns
            Theme={'dark'}
            Split={'30-70'}
            Title={wordpressData.fields.second_text_block.title}
            Copy={wordpressData.fields.second_text_block.copy}
            Button={{
              theme: 'transparent-with-white-outline',
              copy: wordpressData.fields.second_text_block.button_copy,
              link: wordpressData.fields.second_text_block.button_link
            }}
          />

          <ProductSpotlight
            Language={Lang}
            WordpressData={true}
            Title={wordpressData.fields.featured_product_block_one.featured_products.title}
            Items={wordpressData.fields.featured_product_block_one.featured_products.featured_products}
          />

          <LogoGridV2
            Subtitle={wordpressData.fields.industry_partner_logos.subtitle}
            Title={wordpressData.fields.industry_partner_logos.title}
            Copy={wordpressData.fields.industry_partner_logos.copy}
            AdditionalClasses={'left-headers'}
            TitleSize="l"
            SubTitleSize="m"
            Logos={wordpressData.fields.industry_partner_logos.logos}
          />

          <HighlightBlock
            ImageType={'HalfBackground'}
            Theme={'PrimaryGradient'}
            Title={wordpressData.fields.highlight_block_two.title}
            Button={{
              copy: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              ModelTitle: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/energy/energy.png'
            }}
          />

          <ProductSpotlight
            Language={Lang}
            WordpressData={true}
            Title={wordpressData.fields.featured_product_block_two.featured_products.featured_products_title}
            Items={wordpressData.fields.featured_product_block_two.featured_products.featured_products}
          />

          <ArticleBlock
            Link={{
              link: '/news/',
              copy: 'All Insights'
            }}
            MostRecentBlogs={true}
            Title={'Energy Insights'}
            Topic={'energy'}
            Layout={'layout-4'}
          />

          <HighlightBlock
            AdditionalClass={'energy-turbine'}
            ImageType={'HalfBackground'}
            Theme={'PrimaryGradient'}
            Title={wordpressData.fields.highlight_block_three.title}
            Button={{
              copy: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              ModelTitle: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/energy/windmill.png'
            }}
          />

          <Accordion DataType={'wp'} Title={wordpressData.fields.faqs_title} Items={wordpressData.fields.questions} />

          <Footer lang={Lang} Lang={Lang} />
        </PageContainer>
      </>
    );
  } else {
    return null;
  }
};

export default EnergyTemplate;
